import { useForm } from 'react-hook-form'

import {
  Navigation,
  Input,
  Shimmer
} from '@itau-loans-www/shopping/src/components'
import { useProposal, useLead } from '@itau-loans-www/shopping/src/hooks'
import ProposalLayout from '@itau-loans-www/shopping/src/layouts/Proposal'

import '../formalization.scss'
import useAddressFormalization from './hooks'

const AddressFormalization = ({ pageContext }) => {
  const proposalContext = useProposal()
  const { product } = proposalContext
  const hasWppBtn = product?.proposal?.productCode !== 'FGTS'
  const { onSubmit, stateList, submitLoading, getLoading } =
    useAddressFormalization()

  const {
    leadData: { address }
  } = useLead()

  const initialValues = {
    zip_code: address?.zip_code ?? '',
    street: address?.street ?? '',
    number: address?.number ?? '',
    complement: address?.complement ?? '',
    neighborhood: address?.neighborhood ?? '',
    city: address?.city ?? '',
    state: address?.state ?? ''
  }

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const fillForm = ({ city, neighborhood, street, uf }) => {
    setValue('street', street)
    setValue('neighborhood', neighborhood)
    setValue('city', city)
    setValue('state', uf.toUpperCase())
  }

  return (
    <ProposalLayout maxSteps={4} pageContext={pageContext}>
      <div className="formalization">
        <Shimmer
          isVisible={getLoading}
          height="fit-content"
          margin="0 0 20px 0"
        >
          <h1 className="formalization__title">
            Agora, precisamos do seu endereço
          </h1>
        </Shimmer>

        <form className="formalization__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="formalization__form-fields">
            <div className="formalization__input-wrapper--big formalization__input-wrapper--mobile-full">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Input.Cep
                  label="CEP"
                  name="zip_code"
                  onResult={fillForm}
                  control={control}
                />
              </Shimmer>
            </div>

            <div className="formalization__input-wrapper--full">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Input.Text
                  label="Endereço"
                  id="street"
                  name="street"
                  autoComplete="on"
                  control={control}
                  validators={{
                    rules: {
                      validate: {
                        onlyLettersAndNumbers: (value) =>
                          /^[ a-zA-ZÀ-ÿ0-9\u00f1\u00d1./,-]*$/g.test(value)
                      }
                    },
                    messages: {
                      onlyLettersAndNumbers: 'endereço inválido'
                    }
                  }}
                />
              </Shimmer>
            </div>

            <div className="formalization__input-wrapper--big">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Input.Text
                  type="tel"
                  label="Número"
                  id="number"
                  name="number"
                  autoComplete="on"
                  maxLength="5"
                  validators={{
                    rules: {
                      validate: {
                        onlyNumbers: (value) => /^[0-9]+$/.test(value)
                      }
                    },
                    messages: {
                      onlyNumbers: 'número inválido'
                    }
                  }}
                  control={control}
                />
              </Shimmer>
            </div>

            <div className="formalization__input-wrapper--small">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Input.Text
                  label="Complemento"
                  id="complement"
                  name="complement"
                  autoComplete="on"
                  control={control}
                  required={false}
                />
              </Shimmer>
            </div>

            <div className="formalization__input-wrapper--full">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Input.Text
                  label="Bairro"
                  id="neighborhood"
                  name="neighborhood"
                  autoComplete="on"
                  validators={{
                    rules: {
                      validate: {
                        onlyLettersAndNumbers: (value) =>
                          /^[ a-zA-ZÀ-ÿ0-9\u00f1\u00d1./,-]*$/g.test(value)
                      }
                    },
                    messages: {
                      onlyLettersAndNumbers: 'bairro inválido'
                    }
                  }}
                  control={control}
                />
              </Shimmer>
            </div>

            <div className="formalization__input-wrapper--big">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Input.Text
                  label="Cidade"
                  id="city"
                  name="city"
                  autoComplete="on"
                  control={control}
                  validators={{
                    rules: {
                      validate: {
                        onlyLetters: (value) =>
                          /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g.test(value)
                      }
                    },
                    messages: {
                      onlyLetters: 'cidade inválida'
                    }
                  }}
                />
              </Shimmer>
            </div>

            <div className="formalization__input-wrapper--small">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Input.Text
                  label="Estado"
                  id="state"
                  name="state"
                  autoComplete="on"
                  control={control}
                  maxLength="2"
                  validators={{
                    rules: {
                      validate: {
                        onlyLetters: (value) =>
                          /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g.test(value),
                        isValidState: (value) =>
                          stateList.includes(value.toUpperCase())
                      }
                    },
                    messages: {
                      onlyLetters: 'estado inválido',
                      isValidState: 'estado inválido'
                    }
                  }}
                />
              </Shimmer>
            </div>
          </div>

          <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
            <Navigation
              nextStep
              nextButtonType="submit"
              hasWppBtn={hasWppBtn}
              isNextButtonDisabled={!isValid}
              isNextStepButtonLoading={submitLoading}
              nextButtonId="formalization-address-next-btn"
              nextButtonText="Continuar proposta"
            />
          </Shimmer>
        </form>
      </div>
    </ProposalLayout>
  )
}

export default AddressFormalization

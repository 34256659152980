import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'

const getLeadAddress = async (type) => {
  const { data } = await defaultLeadsApiCall({
    endpoint: `address/${type}`,
    method: 'get'
  })

  return data
}
const putLeadAddress = async (requestBody, type) => {
  const formatedBody = {
    city: requestBody.city,
    complement: requestBody.complement,
    neighborhood: requestBody.neighborhood,
    number: requestBody.number,
    state: requestBody.state.toUpperCase(),
    street: requestBody.street,
    zip_code: requestBody.zip_code.replace(/[^0-9]/g, '')
  }

  const { data } = await defaultLeadsApiCall({
    endpoint: `address/${type}`,
    method: 'put',
    body: formatedBody
  })

  return data
}

export default { getLeadAddress, putLeadAddress }
